import {defineStore} from "pinia";
import {reactive} from "vue";

export const useUserStore = defineStore('user', () => {
  const user = reactive({
    userInfo: {
      userName: '',
      deptName: null,
      avatar: "",
      isLeader: 0,
      token: null,
      userId: import.meta.env.VITE_NODE_ENV === 'dev'?'CeyqgvybZgx6oRqbLqa2DdBpvl/Epxum': '',
    }
  });

  const setUserInfo = (data) => {
    user.userInfo = data;
  }
  return {
    user,
    setUserInfo,
  }
})