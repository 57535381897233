<script setup>
import {useRouter} from "vue-router";

let router = useRouter()
console.log(router);
</script>

<template>
  <div>404</div>
</template>

<style scoped>

</style>