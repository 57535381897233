import 'vant/lib/index.css';
import '@/utils/styles/main.scss'
import 'animate.css'

import { createApp } from 'vue'
import pinia from '@/stores';
import App from './App.vue'
import router from './router'
import eruda from "eruda";
import "default-passive-events"

if (import.meta.env.MODE !== 'production'){
  eruda.init();
}

const app = createApp(App)
app.use(pinia).use(router)

app.mount('#app')
