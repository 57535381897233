import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import view404 from '../views/error/404View.vue'
import {useUserStore} from "@/stores/user.js";
import {storeToRefs} from "pinia";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/index',
    },
    {
      path: '/index',
      name: 'home',
      component: HomeView,
      meta: {login: false, cName: '首页'},
    },
    {
      path: '/404',
      name: '404',
      component: view404,
      meta: {login: false, cName: '404'},
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login/LoginView.vue'),
      meta: {login: false, cName: '登录'},
    },
    {
      path: '/login/wx',
      name: 'WxLogin',
      component: () => import('@/views/login/WxLoginView.vue'),
      meta: {login: false, cName: '登录'},
    },
    {
      path: '/login/qw',
      name: 'QwLogin',
      component: () => import('@/views/login/LoginQwView.vue'),
      meta: {login: false, cName: '登录'},
    },
    {
      path: '/login/tip',
      name: 'LoginTip',
      component: () => import('@/views/login/LoginTipView.vue'),
      meta: {login: false, cName: '引导页'},
    },
    {
      path: '/question',
      name: 'Question',
      component: () => import('@/views/question/QuestionView.vue'),
      meta: {login: true, cName: '问卷', transition: true},
    },
    {
      path: '/question/desc',
      name: 'QuestionDesc',
      component: () => import('@/views/question/QuestionDescView.vue'),
      meta: {login: true, cName: '问卷', loginTo: 'question', transition: true},
    },
    {
      path: '/error/env',
      name: 'EnvError',
      component: () => import('@/views/error/EnvErrorView.vue'),
      meta: {login: false, cName: 'Error', transition: true},
    },
    {path: "/:pathMatch(.*)*", redirect: "/404", hidden: true, meta: {transition: true}},
  ]
})


router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/404') {
    next()
    return
  }
  let userStore = useUserStore();
  let {user} = storeToRefs(userStore);
  // 判断是否需要登录权限
  if (to.meta.login) {
    if (user.value.userInfo.userId) {
      // 已登录
      next()
    } else {
      // 未登录
      next({
        path: '/login', query: {
          r: to.meta.loginTo || to.path.replace('\/', ''),
          ...to.query,
          pt: localStorage.getItem('pt') || '1'
        },
        replace: true
      })
    }
  } else {
    next()
  }
})

export default router
