<script setup>
import {useThemeStore} from "@/stores/theme.js";
import {storeToRefs} from "pinia";
import {showNotify} from "vant";
import {onUnmounted} from "vue";
import {useRoute} from "vue-router";


const themeStore = useThemeStore();
const {theme} = storeToRefs(themeStore);
const route = useRoute();
const offlineToast = () => {
  showNotify({ message: '网络连接断开' });
}

onUnmounted(() => {
  window.addEventListener('offline', offlineToast)
})

</script>

<template>
  <van-config-provider :theme="theme">
    <router-view v-slot="{ Component }">
      <transition v-if="route.meta.transition" enter-active-class="animate__animated animate__fadeInRight animate__faster"
                  leave-to-class="router-leave-from"
      >
        <component :is="Component" />
      </transition>
      <component :is="Component" v-else />
    </router-view>
  </van-config-provider>
</template>
