import {defineStore} from "pinia";
import {ref} from "vue";
import {parse, stringify} from "zipson/lib";

export const useThemeStore = defineStore('theme', () => {
  // 主题
  const theme = ref('light')

  // 切换主题
  const toggleTheme = () => {
    theme.value = theme.value === 'dark' ? 'light' : 'dark'
  }

  return {
    theme,
    toggleTheme,
  }
}, {
  persist: {
    key: 'theme',
    serializer: {
      deserialize: parse,
      serialize: stringify,
    }
  }
})